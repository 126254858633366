
import { Component, Prop, Vue } from "vue-property-decorator";
import { Resource as IResource } from "@/interfaces/Resource.interface";
import Breadcrumb from "@/components/Breadcrumb.vue";
import SocialButtons from "@/components/SocialButtons.vue";
import CardShowcase from "@/components/CardShowcase.vue";
import PageBreak from "@/components/PageBreak.vue";
import BlogPostCard from "@/components/cards-ui/BlogPostCard.vue";
import VueMarkdown from "vue-markdown";

@Component({
  components: {
    VueMarkdown,
    Breadcrumb,
    SocialButtons,
    CardShowcase,
    PageBreak,
    BlogPostCard
  }
})
export default class BlogPost extends Vue {
  @Prop() private blogPost!: IResource;
  @Prop() private relatedPosts!: Record<string, any>;
  
  @Prop() private loadingStatus!: string;

  private breadcrumb = {
    crumbs: [
      {
        title: "Blog",
        link: "/resources"
      }
    ]
  };
}
