
import { Component, Vue, Watch } from "vue-property-decorator";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import BlogPost from "../components/resource-posts/BlogPost.vue";
import CaseStudy from "../components/resource-posts/CaseStudy.vue";
import { BlogCardData as IBlogCardData } from "@/interfaces/BlogCardData.interface";
import ApiService from "../services/ApiService";

@Component<any>({
  metaInfo() {
    return {
      title: `Leighton Resources - ${this.blogPost.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `${this.blogPost.description}`
        }
      ]
    };
  },
  components: {
    LayoutDefault,
    BlogPost,
    CaseStudy
  }
})
export default class Blog extends Vue {
  @Watch("$route")
  onUrlChange(to: Record<string, unknown>, from: Record<string, unknown>) {
    if (to !== from) {
      this.prepPage();
    }
  }

  public $router: any;

  private blogPost = {
    id: "",
    type: "",
    title: this.$route.params.slug
      .replaceAll("-", " ")
      .toLowerCase()
      .replace(/\b(\w)/g, a => a.toUpperCase()),
    description: "",
    content: "",
    slug: ""
  };
  private relatedLoading = true;
  private loadingStatus = "LOADING";
  private relatedLoadingStatus = "LOADING";

  private relatedPosts = {
    title: "Related Posts",
    showcaseTypeDesktop: "static-blog",
    showcaseTypeMobile: "single-scroller-blog",
    customClass: "blog-post--cards",
    values: Array<IBlogCardData>()
  };

  mounted() {
    this.prepPage();
  }

  private async prepPage() {
    this.relatedPosts.values.splice(0); // Non-destructive array reset
    this.relatedPosts.values.push({}, {}, {}, {});

    await this.getPost(this.$route.params.slug);
  }

  private async getPost(slug: string) {
    this.loadingStatus = "LOADING";

    const allPosts = await ApiService.get(
      `https://www.leighton.com/resources/blog/allPosts.json`
    );

    if (allPosts.length) {
      for (let i = 0; i < allPosts.length; i++) {
        const blogPost = allPosts[i];

        if (blogPost.slug === `${slug}`) {
          this.blogPost = blogPost;
          this.relatedPosts.values = this.getRandom(
            allPosts.slice(0, 12),
            blogPost.type == "casestudy" ? 3 : 4
          );
        }
      }
    }
    if (!this.blogPost.id) {
      this.$router.push({ path: "/404" });
    } else {
      this.loadingStatus = "COMPLETE";
    }

    return true;
  }

  private getRandom(arr: Array<IBlogCardData>, n: number) {
    const result = new Array(n);
    let len = arr.length;
    const taken = new Array(len);
    if (n > len) return arr;
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
}
