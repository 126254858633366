
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SocialButtons extends Vue {
  @Prop() private shareDescription!: string;
  @Prop() private shareQuote!: string;
  @Prop() private shareTitle!: string;
  @Prop() private imageVariant!: string;

  private calculateURL(): string {
    let pageURL = "";

    pageURL = window.location.href;

    return pageURL;
  }

  private networks = [
    {
      network: "facebook",
      name: "Facebook",
      image: require(`@/assets/social/facebook-circle${this.imageVariant ? '-' + this.imageVariant : ''}.png`),
      imageAlt: "Share on Facebook"
    },
    {
      network: "pinterest",
      name: "Pinterest",
      image: require(`@/assets/social/pinterest-circle${this.imageVariant ? '-' + this.imageVariant : ''}.png`),
      imageAlt: "Pin on Pinterest"
    },
    {
      network: "twitter",
      name: "Twitter",
      image: require(`@/assets/social/twitter-circle${this.imageVariant ? '-' + this.imageVariant : ''}.png`),
      imageAlt: "Share on Twitter"
    },
    {
      network: "linkedin",
      name: "LinkedIn",
      image: require(`@/assets/social/linkedin-circle${this.imageVariant ? '-' + this.imageVariant : ''}.png`),
      imageAlt: "Share on Linkedin"
    }
  ];
}
